/* Fonts */
:root {
    --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Nunito", sans-serif;
    --nav-font: "Inter", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
    --background-color: #ffffff;
    /* Background color for the entire website, including individual sections */
    --default-color: #3d4348;
    /* Default color used for the majority of the text content across the entire website */
    --heading-color: #3e5055;
    /* Color for headings, subheadings and title throughout the website */
    --accent-color: #1c7ff2;
    /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff;
    /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff;
    /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
    --nav-color: #313336;
    /* The default color of the main navmenu links */
    --nav-hover-color: #1c7ff2;
    /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: #ffffff;
    /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: #ffffff;
    /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #313336;
    /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #1c7ff2;
    /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
    --background-color: #f8fbfc;
    --surface-color: #ffffff;
}

.dark-background {
    --background-color: #060606;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #252525;
    --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/
body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
}

a {
    color: var(--accent-color);
    text-decoration: none !important;
    transition: 0.3s;
}

a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
}

/* PHP Email Form Messages
------------------------------*/
.php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.php-email-form .loading {
    display: none;
    background: var(--surface-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--surface-color);
    animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 20px 0;
    transition: all 0.5s;
    z-index: 997;
}

.header .logo {
    line-height: 1;
}

.header .logo img {
    max-height: 48px;
    margin-right: 20px;
    margin-left: -10px;
}

.logo-image {
    scale: 1.3;
    margin-right: 20px !important;
    margin-left: 10px;
}

.header .logo h1 {
    font-size: 30px;
    margin: 0;
    color: var(--heading-color);
    font-family: "Inter", sans-serif;
    font-weight: 400;
}

@media (max-width: 480px) {
    .header .logo img {
        max-height: 35px;
        margin-right: 12px !important;
    }

    .header .logo h1 {
        font-size: 24px;
        font-weight: 300;
    }
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 14px;
    padding: 8px 25px;
    margin: 0 0 0 30px;
    border-radius: 50px;
    transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

@media (max-width: 1200px) {
    .header .logo {
        order: 1;
    }

    .header .btn-getstarted {
        order: 2;
        margin: 0 15px 0 0;
        padding: 6px 15px;
    }

    .header .navmenu {
        order: 3;
    }

    .header .logo img {
        margin-left: 10px !important;
    }
}

.scrolled .header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/* Index Page Header
  ------------------------------*/
.index-page .header {
    --background-color: rgba(255, 255, 255, 0);
}

/* Index Page Header on Scroll
  ------------------------------*/
.index-page.scrolled .header {
    --background-color: #ffffff;
}


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
@media (min-width: 1200px) {
    .navmenu {
        padding: 0;
    }

    .navmenu ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navmenu li {
        position: relative;
    }

    .navmenu a,
    .navmenu a:focus {
        color: var(--nav-color);
        padding: 18px 15px;
        font-size: 16px;
        font-family: var(--nav-font);
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        transition: 0.3s;
    }

    .navmenu li:last-child a {
        padding-right: 0;
    }

    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
        color: var(--nav-hover-color);
    }

    .navmenu .dropdown ul {
        margin: 0;
        padding: 10px 0;
        background: var(--nav-dropdown-background-color);
        display: block;
        position: absolute;
        visibility: hidden;
        left: 14px;
        top: 130%;
        opacity: 0;
        transition: 0.3s;
        border-radius: 4px;
        z-index: 99;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }

    .navmenu .dropdown ul li {
        min-width: 200px;
    }

    .navmenu .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        color: var(--nav-dropdown-color);
    }

    .navmenu .dropdown ul a i {
        font-size: 12px;
    }

    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navmenu .dropdown .dropdown ul {
        top: 0;
        left: -90%;
        visibility: hidden;
    }

    .navmenu .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: -100%;
        visibility: visible;
    }

    .navmenu .megamenu {
        position: static;
    }

    .navmenu .megamenu ul {
        margin: 0;
        padding: 10px;
        background: var(--nav-dropdown-background-color);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 130%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        display: flex;
        transition: 0.3s;
        border-radius: 4px;
        z-index: 99;
    }

    .navmenu .megamenu ul li {
        flex: 1;
    }

    .navmenu .megamenu ul li a,
    .navmenu .megamenu ul li:hover>a {
        padding: 10px 20px;
        font-size: 15px;
        color: var(--nav-dropdown-color);
    }

    .navmenu .megamenu ul li a:hover,
    .navmenu .megamenu ul li .active,
    .navmenu .megamenu ul li .active:hover {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .megamenu:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }
}

/* Mobile Navigation */
@media (max-width: 1199px) {
    .mobile-nav-toggle {
        color: var(--nav-color);
        font-size: 28px;
        line-height: 0;
        margin-right: 10px;
        cursor: pointer;
        transition: color 0.3s;
    }

    .navmenu {
        padding: 0;
        z-index: 9997;
    }

    .navmenu ul {
        display: none;
        position: absolute;
        inset: 60px 20px 20px 20px;
        padding: 10px 0;
        margin: 0;
        border-radius: 6px;
        background-color: var(--nav-mobile-background-color);
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }

    .navmenu a,
    .navmenu a:focus {
        color: var(--nav-dropdown-color);
        padding: 10px 20px;
        font-family: var(--nav-font);
        font-size: 17px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.3s;
        background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }

    .navmenu a i:hover,
    .navmenu a:focus i:hover {
        background-color: var(--accent-color);
        color: var(--contrast-color);
    }

    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .active i,
    .navmenu .active:focus i {
        background-color: var(--accent-color);
        color: var(--contrast-color);
        transform: rotate(180deg);
    }

    .navmenu .dropdown ul {
        position: static;
        display: none;
        z-index: 99;
        padding: 10px 0;
        margin: 10px 20px;
        background-color: var(--nav-dropdown-background-color);
        border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
        transition: all 0.5s ease-in-out;
        box-shadow: none;
    }

    .navmenu .dropdown ul ul {
        background-color: rgba(33, 37, 41, 0.1);
    }

    .navmenu .dropdown>.dropdown-active {
        display: block;
        background-color: rgba(33, 37, 41, 0.03);
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .mobile-nav-toggle {
        color: #fff;
        position: absolute;
        font-size: 32px;
        top: 15px;
        right: 15px;
        margin-right: 0;
        z-index: 9999;
    }

    .mobile-nav-active .navmenu {
        position: fixed;
        overflow: hidden;
        inset: 0;
        background: rgba(33, 37, 41, 0.8);
        transition: 0.3s;
    }

    .mobile-nav-active .navmenu>ul {
        display: block;
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 100vh;
    background: white;
    border-bottom: 2px solid white;
}

#hero .container {
    padding-top: 80px;
}

#hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    color: #434175;
    font-family: "Poppins", sans-serif;
}

#hero h2 {
    margin: 10px 0 0 0;
    font-size: 20px;
}

#hero ul {
    margin-top: 15px;
    list-style: none;
    padding: 0;
}

#hero ul li {
    padding: 10px 0 0 28px;
    position: relative;
}

#hero ul i {
    left: 0;
    top: 7px;
    position: absolute;
    font-size: 20px;
    color: #5a5af3;
}

#hero .btn-get-started,
#hero .btn-get-quote {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 8px 30px 9px 30px;
    margin-bottom: 15px;
    border-radius: 3px;
    transition: 0.5s;
    border-radius: 50px;
}

#hero .btn-get-started {
    background: var(--accent-color);
    color: #fff;
    border: 2px solid var(--accent-color);
    margin-right: 10px;
}

#hero .btn-get-started:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
    border-color: color-mix(in srgb, var(--accent-color), transparent 15%);
}

#hero .btn-get-quote {
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
}

#hero .btn-get-quote:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
    color: #fff;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    #hero .hero-img {
        text-align: center;
    }

    #hero .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero {
        text-align: center;
    }

    #hero ul {
        text-align: left;
        font-size: 14px;
    }

    #hero .hero-img img {
        width: 80%;
    }

    #hero .btn-get-started,
    #hero .btn-get-quote {
        padding-left: 18px;
        padding-right: 18px;
        font-size: 14px;
    }
}


/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
    --background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 25px 0;
    position: relative;
}

.page-title h1 {
    font-size: 24px;
    font-weight: 700;
}

.page-title .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.page-title .breadcrumbs ol li+li {
    padding-left: 10px;
}

.page-title .breadcrumbs ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
section,
.section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 100px;
    overflow: clip;
}

@media (max-width: 1199px) {

    section,
    .section {
        scroll-margin-top: 66px;
    }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 700;
    position: relative;
}

.section-title h2:before,
.section-title h2:after {
    content: "";
    width: 50px;
    height: 2px;
    background: var(--accent-color);
    display: inline-block;
}

.section-title h2:before {
    margin: 0 15px 10px 0;
}

.section-title h2:after {
    margin: 0 0 10px 15px;
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Features Details Section
--------------------------------------------------------------*/
.features-details .features-item+.features-item {
    margin-top: 60px;
}

.features-details .features-item .content {
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: color-mix(in srgb, var(--default-color), transparent 10%);
    padding: 30px;
    border-radius: 10px;
}

.features-details .features-item h3 {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 15px;
}

.features-details .features-item .more-btn {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 6px 30px;
    border-radius: 6px;
}

.features-details .features-item .more-btn:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.features-details .features-item ul {
    list-style: none;
    padding: 0;
}

.features-details .features-item ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.features-details .features-item ul li:last-child {
    padding-bottom: 0;
}

.features-details .features-item ul i {
    font-size: 20px;
    margin-right: 10px;
    color: var(--accent-color);
}

.features-details .features-item img {
    border-radius: 15px;
}


/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients .clients-wrap {
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    border-left: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
}

.clients .client-logo {
    background-color: var(--surface-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    overflow: hidden;
}

.clients .client-logo img {
    padding: 50px;
    max-width: 80%;
    transition: 0.3s;
}

@media (max-width: 640px) {
    .clients .client-logo img {
        padding: 30px;
        max-width: 50%;
    }
}

.clients .client-logo:hover img {
    transform: scale(1.1);
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
    background-color: var(--surface-color);
    box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.1);
    border-top: 4px solid var(--background-color);
    padding: 60px 40px;
    height: 100%;
    border-radius: 5px;
}

.pricing h3 {
    font-weight: bolder;
    margin-bottom: 35px;
    font-size: 20px;
    /* text-transform: ; */
}

.pricing h4 {
    color: var(--accent-color);
    font-size: 40px;
    font-weight: 400;
    font-family: var(--heading-font);
    margin-bottom: 0;
}

.pricing h4 sup {
    font-size: 28px;
}

.pricing h4 span {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 18px;
}

.pricing ul {
    padding: 20px 0;
    list-style: none;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    text-align: left;
    line-height: 20px;
}

.pricing ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.pricing ul i {
    color: #059652;
    font-size: 24px;
    padding-right: 3px;
}

.pricing ul .na {
    visibility: hidden !important;
    color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.pricing ul .na i {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .buy-btn {
    color: var(--accent-color);
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 50px;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--heading-font);
    transition: 0.3s;
    border: 1px solid var(--accent-color);
}

.pricing .buy-btn:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 15%) !important;
    color: var(--contrast-color);
}

.pricing .featured {
    border-top-color: var(--accent-color);
}

.pricing .featured .buy-btn {
    background: var(--accent-color);
    color: var(--contrast-color);
}

/* .container.pricing-duarations {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 45px;
    z-index: 999;
} */

.container.pricing-duarations {
    display: block;
    text-align: center;
    margin-top: 20px;
    position: static;
    z-index: 999;
}

.pseudo-link {
    color: var(--accent-color);
    /* opacity: 0.8; */
    cursor: pointer;
}

.pricing-duaration {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    background: var(--contrast-color);
    padding: 7px 20px;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    z-index: 999;
}

.pricing-duaration.active {
    margin: 0 20px;
    background: var(--accent-color);
    color: var(--contrast-color);
    padding: 7px 20px;
    border-radius: 50px;
    font-size: 16px;
}

.pricing-duaration:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
    color: #fff;
}

.pricing-duaration.active::before {
    content: "✓ ";
    margin-right: 10px;
}

.bronze-heading {
    background: linear-gradient(183deg, rgb(84 210 202) 27%, rgb(22 96 151) 48%, rgb(74 180 199) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gold-heading {
    background: linear-gradient(172deg, rgb(79 125 255) 27%, rgb(32 68 221) 48%, rgb(96 142 254) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.platinum-heading {
    background: linear-gradient(180deg, rgb(172 106 255) 27%, rgb(217 104 251) 40%, rgb(36 13 105) 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 992px) {
    .pricing .box {
        max-width: 60%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 767px) {
    .pricing .box {
        max-width: 80%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 420px) {
    .pricing .box {
        max-width: 100%;
        margin: 0 auto 30px auto;
    }

    .pricing-duaration {
        cursor: none !important;
    }
}

/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faq .section-title {
    padding-bottom: 20px;
}

.faq .faq-container .faq-item {
    position: relative;
    padding: 20px;
    margin-bottom: 15px;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    border-radius: 5px;
    overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
    margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 30px 0 0;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.faq .faq-container .faq-item h3 .num {
    color: var(--accent-color);
    padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
    color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    line-height: 0;
    transition: 0.3s;
    cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
    color: var(--accent-color);
}

.faq .faq-container .faq-active {
    background-color: color-mix(in srgb, var(--accent-color), transparent 97%);
    border-color: color-mix(in srgb, var(--accent-color), transparent 80%);
}

.faq .faq-container .faq-active h3 {
    color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
    transform: rotate(90deg);
    color: var(--accent-color);
}


/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
    background-color: var(--surface-color);
    padding: 20px 0 30px 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contact .info-item i {
    font-size: 20px;
    color: var(--accent-color);
    width: 56px;
    height: 56px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    border: 2px dotted color-mix(in srgb, var(--accent-color), transparent 40%);
}

.contact .info-item h3 {
    font-size: 20px;
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
}

.contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
}

.contact .php-email-form {
    background-color: var(--surface-color);
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
    .contact .php-email-form {
        padding: 20px;
    }
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: var(--surface-color);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
    padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    background-color: var(--surface-color);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    position: relative;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid var(--background-color);
    position: absolute;
    left: -45px;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
    margin: 0;
}

.testimonials .testimonial-item .stars {
    margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 60%);
    font-size: 26px;
    line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
}

.testimonials .swiper-wrapper {
    height: auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: var(--background-color);
    opacity: 1;
    border: 1px solid var(--accent-color);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
}

@media (max-width: 767px) {
    .testimonials .testimonial-wrap {
        padding-left: 0;
    }

    .testimonials .testimonials-carousel,
    .testimonials .testimonials-slider {
        overflow: hidden;
    }

    .testimonials .testimonial-item {
        padding: 30px;
        margin: 15px;
    }

    .testimonials .testimonial-item .testimonial-img {
        position: static;
        left: auto;
    }
}

/*--------------------------------------------------------------
# More Features Section
--------------------------------------------------------------*/
.more-features .features-image {
    position: relative;
    min-height: 400px;
}

.more-features .features-image img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.more-features h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
}

.more-features .icon-box {
    margin-top: 30px;
}

.more-features .icon-box i {
    color: var(--accent-color);
    margin-right: 15px;
    font-size: 24px;
    line-height: 1.2;
}

.more-features .icon-box h4 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
}

.more-features .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.more-features .who-we-are {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
}

/*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
.footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    padding-bottom: 50px;
    position: relative;
}

.footer .footer-top {
    padding-top: 50px;
}

.footer .footer-about .logo {
    line-height: 1;
    margin-bottom: 25px;
}

.footer .footer-about .logo img {
    max-height: 40px;
    margin-right: 6px;
}

.footer .footer-about .logo span {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: var(--heading-font);
    color: var(--heading-color);
}

.footer .footer-about p {
    font-size: 14px;
    font-family: var(--heading-font);
}

.footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin-right: 10px;
    transition: 0.3s;
}

.footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}

.footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
}

.footer .footer-links {
    margin-bottom: 30px;
}

.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-links ul i {
    padding-right: 2px;
    font-size: 12px;
    line-height: 0;
}

.footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.footer .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-links ul a {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    display: inline-block;
    line-height: 1;
}

.footer .footer-links ul a:hover {
    color: var(--accent-color);
}

.footer .footer-contact p {
    margin-bottom: 5px;
}

.footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 6px 8px;
    position: relative;
    border-radius: 50px;
    border: 1px solid color-mix(in srgb, var(--accent-color), transparent 70%);
    display: flex;
    background-color: var(--surface-color);
    transition: 0.3s;
}

.footer .footer-newsletter .newsletter-form:focus-within {
    border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type=email] {
    border: 0;
    padding: 4px 10px;
    width: 100%;
    background-color: var(--contrsast-color);
    color: var(--default-color);
}

.footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
    outline: none;
}

.footer .footer-newsletter .newsletter-form input[type=submit] {
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: -7px -9px -7px 0;
    background: var(--accent-color);
    color: var(--contrast-color);
    transition: 0.3s;
    border-radius: 50px;
}

.footer .footer-newsletter .newsletter-form input[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.footer .copyright {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
    margin-bottom: 0;
}

.footer .credits {
    margin-top: 6px;
    font-size: 13px;
}